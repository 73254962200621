.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  padding-top: 25px;
  padding-bottom: 50px;
  margin: 20px 0 0 0;
}

.hero {
  margin: 50px 0 75px 0;
}